import { Message } from '@arco-design/web-react';
import axios from 'axios';

const instance = axios.create({
 baseURL: process.env.REACT_APP_BASE_URL || '#',
  //method: 'post',
 //timeout: 5000,//    
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
   'Authorization': localStorage.getItem('token')
  },
});
// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 如果返回的 res.data.code 等于 '4010'，则退出登录
    const { code,message } = response.data;
    if (response.data && response.data.code === '4010' && window.location.pathname.replace(/\//g, '') !== 'login' ) {
      // 退出登录逻辑
      logout();
      return Promise.reject(new Error('Unauthorized'));
    }else if (code==='0000') {
    // 否则返回响应
    return response;
   }else{
    Message.error(message);
    console.log('失败信息',response);
    // return Promise.reject(new Error(message));
    return;
  }
  }, 
  (error) => {
    console.log('失败信息',error);
    // return Promise.reject(error);
    return;
  }
);

// 退出登录函数
function logout() {
  // 清除本地存储的用户信息
  localStorage.getItem('token') ? localStorage.removeItem('token') : '' ;
  // 重定向到登录页面
  window.location.href = '/login';
}

// 登录接口封装
export const login = async (username, password) => {
  try {
    const response = await instance.post('/login', {
      username,
      password,
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};


export default instance;