import React, { useReducer, useState } from 'react';
import { Button, Card, Typography } from '@arco-design/web-react';
import { useParams,useLocation } from 'react-router-dom';

interface apiProps {
  id: string;
}

const ApiDocumentation : React.FC<apiProps> = ({id}) =>{

//均无用
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const myParam = searchParams.get('key');
    // // 使用 myParam 参数
    // console.log('myParam',myParam);
   // const {id}  = useParams();  
   // console.log('id',id);
    console.log('id',id);
    //获取url中的参数

  const [response, setResponse] = useState({response_mode: 'blocking',
    message_id: id,
    conversation_id: 'abcdef',
    mode: 'chat',
    answer: '这是示例的回答内容。',
    metadata: {},
    usage: {
      prompt_tokens: 10,
      completion_tokens: 50,
      total_tokens: 60,
    },
    retriever_resources: [],});

  const fetchData = async () => {
    try {
      const res = await fetch(`/api/your-endpoint/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ yourParameter: 'value' }),
      });
      const data = await res.json();
      setResponse(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div>
      <Button onClick={fetchData}>API 文档</Button>

      {response && (
        <Card>
        <div>
                      <Typography.Title heading={3}>模型名称</Typography.Title>
                      <Typography.Paragraph></Typography.Paragraph>
                      <Typography.Title heading={3}>输入类型</Typography.Title>
                      <Typography.Paragraph></Typography.Paragraph>
                      <Typography.Title heading={3}>入参</Typography.Title>
                      <Typography.Paragraph></Typography.Paragraph>
                      <Typography.Title heading={3}>最大携带聊天记录轮次</Typography.Title>
                      <Typography.Paragraph></Typography.Paragraph>
                      <Typography.Title heading={3}>首次提示消息</Typography.Title>
                      <Typography.Paragraph></Typography.Paragraph>

                      <Typography.Title heading={3}>节点配置</Typography.Title>
                      <Typography.Paragraph></Typography.Paragraph>

                      <Typography.Title heading={3}>知识库引用</Typography.Title>
                      <Typography.Paragraph></Typography.Paragraph>

                      <Typography.Title heading={3}>提示词</Typography.Title>
                      <Typography.Paragraph>{"key1"}{"key2"}</Typography.Paragraph>

                      <Typography.Title heading={3}>输出方式</Typography.Title>
                      <Typography.Paragraph></Typography.Paragraph>

                      <Typography.Title heading={3}>Response</Typography.Title>
                      <Typography.Paragraph>
                          <strong>response_mode:</strong> {response.response_mode}
                      </Typography.Paragraph>

                      <Typography.Title heading={5}>ChatCompletionResponse</Typography.Title>
                      <Typography.Paragraph>
                          <strong>message_id:</strong> {response.message_id}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                          <strong>conversation_id:</strong> {response.conversation_id}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                          <strong>mode:</strong> {response.mode}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                          <strong>answer:</strong> {response.answer}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                          <strong>metadata:</strong> {JSON.stringify(response.metadata, null, 2)}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                          <strong>usage:</strong> {JSON.stringify(response.usage, null, 2)}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                          <strong>retriever_resources:</strong> {JSON.stringify(response.retriever_resources, null, 2)}
                      </Typography.Paragraph>
        </div>
        </Card>
      )}
    </div>
  );
}

export default ApiDocumentation;