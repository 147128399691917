import React ,{useState,useEffect}from 'react';
import { Layout,Form,Switch ,Message,Badge,Popconfirm,Tag,Modal,Input,Pagination, Card, Grid, Typography,Input as ArcoInput, Divider, Button } from '@arco-design/web-react';
import { IconDelete, IconFile, IconRefresh } from '@arco-design/web-react/icon';
import instance from '@/api/instance';
import Footer from '@/components/Footer';

const { Header, Content } = Layout;
const { Row, Col } = Grid;
const { Title } = Typography;
const { Search } = Input;

const data = [
  // 示例数据，根据实际需要进行调整
  { id: '001', title: '面试篇', status: '已启用',question:'学习目标？', answer: '学习目标 面试篇 ...' },
  { id: '002', title: '前置知识', status: '已启用', question:'前置知识？',answer: '01 前置知识' },
  // 添加更多数据段落
];

function NotesPage({record}) {

    const [hoveredCard, setHoveredCard] = useState(null); // 记录悬浮的卡片 ID
    const [visible, setVisible] = useState(false); // 控制模态框的显示与隐藏
    const [selectedCard, setSelectedCard] = useState(null); // 记录选中的卡片数据
    const [addCardText, setAddCardText] = useState({question:'', answer: ''}); // 记录新增卡片的文本内容
    const [addVisible, setAddVisible] = useState(false); // 控制新增卡片模态框的显示与隐藏
    const [stateVisible, setStateVisible] = useState(false); // 控制侧边栏的显示与隐藏

    const [pageSize, setPageSize] = useState(20); // 每页显示的条数
    const [currentPage, setCurrentPage] = useState(1); // 当前页码
    const  [qaDataList,setQaDataList  ] = useState<any>(); // 数据列表

    const [searchParams,setSearchParams ] = useState<any>();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loading, setLoading] = useState(false);


    // useEffect(() => {
    //   getQaDataList(currentPage, pageSize);
    // }),[]; 这样写会导致页面一直刷新，导致请求数据多次，所以改成下面这种写法

    useEffect(() => {
      getQaDataList(currentPage, pageSize);
    }, [searchParams]);

    const getQaDataList = (pageNo, pageSizes) => {
      const isMounted = true; // 标记组件是否挂载
      setPageSize(pageSizes);
      setCurrentPage(pageNo);
      instance.post('/api/knowledge/listQAs',
        {
          pageNo: pageNo,
          pageSize: pageSizes,
          queryFilter: {
            docId: record.docId,
            question: searchParams ? searchParams : undefined //去掉空串
  
          }
        }
      ).then(res => {
        if(res && res.data && res.data.code==='0000'){
          if (isMounted) { // 只有在组件挂载时才更新状态
            console.log('QA res', res);
            setQaDataList(res.data.data);
          }
        }
      }).finally(() => {
        if (isMounted) { // 只有在组件挂载时更新 loading 状态
          setLoading(false);
        }
      })
    }



    const onClose = () => {
      setStateVisible(!stateVisible);
    };
  
    const handleAddCard = () => {
      setAddVisible(true);
    };

    const handleAddModalCancel = () => {
        setAddVisible(false);
        setAddCardText({question:'', answer: ''});
    };
 
    
    const handleCardClick = (item) => {
      setSelectedCard(item);
      setVisible(true);
    };
  
    // 处理模态框确认按钮的逻辑，例如保存编辑后的内容 TODO:更新成功但是返回失败
    const handleModalOk = async() => {
      console.log('保存编辑后的内容:', selectedCard);
      //selectedCard中的数据不为空时才更新
        //判断内容是否为空
      if(selectedCard.question === '' || selectedCard.answer === '' ){
        Message.error('问题或答案不能为空');
        return;
      }
      instance.post('/api/knowledge/updateQA',{
        qaId:selectedCard.qaId,
        question:selectedCard.question,
        answer:selectedCard.answer
      }).then((res) => {
        if(res && res.data && res.data.code === '0000') {
        console.log('更新成功', res);
        Message.success('更新成功');
        //setEditVisible(false);
        setSelectedCard(null);
      //  formEdit.resetFields();
        getQaDataList(currentPage,pageSize);//可能涉及顺序
        } 
      })
      //getQaDataList(currentPage,pageSize);//测试用
      setVisible(false);
    };


const handleAddModalOk = async() => {

  //判断内容是否为空
  if(addCardText.question === '' || addCardText.answer === ''){
    Message.error('问题或答案不能为空');
    return;
    }
//新增   
  setLoading(true);
  instance.post('/api/knowledge/addQAAutoEmbedding',{
    docId:record.docId,
    ...addCardText
  }).then((res) => {
    if(res && res.data && res.data.code === '0000') {
      
      console.log('新增分段', res);
      Message.success('新增成功');
      setAddVisible(false);
      setSelectedCard(null);
      setAddCardText({question:'', answer: ''});
      getQaDataList(currentPage,pageSize);//可能涉及顺序
    }
  }).catch((err) => {
    Message.error(err);
  })
  .finally(() => {
    setLoading(false);
  });

 }

 
 const handleQaDelete = (id) => {
  setDeleteLoading(true);
  instance.post(`/api/knowledge/delQA/${id}`, { id: id }).then(res => {
    if (res && res.data && res.data.code === '0000') {
      Message.success('删除成功');
      getQaDataList(currentPage,pageSize);//可能涉及顺序
      setDeleteLoading(false);
    }
  }).catch(err => {
    Message.error('网络错误');
  })
  ;
  
}
  
    const handleModalCancel = () => {
      setVisible(false);
    };

  //    //获取问答数据
  //const getQaDataList = (pageNo, pageSizes) => {
  //  setPageSize(pageSizes);
  //  setCurrentPage(pageNo);
  //  instance.post('/api/knowledge/listQAs',
  //    {
  //      pageNo: pageNo,
  //      pageSize: pageSizes,
  //      queryFilter: {
  //        docId: record.docId,
  //        question: searchParams ? searchParams : undefined //去掉空串

  //      }
  //    }
  //  ).then(res => {
  //    console.log('QA res', res);
  //    setQaDataList(res.data.data);
  //  })
  //}

    const getTitle = () => {
      return (
        <div  style={{ display: 'flex',gap: '20%'}}>
        <div style={{ display: 'flex', justifyContent: 'center',alignItems: 'center' }}>
        {/* <Switch
          style={{ margin: '0 8px', }}
          size='small'
          checked={stateVisible}
          onChange={onClose}
        /> */}
        {/* <Typography.Text>{stateVisible ? '已启用' : '已禁用' }</Typography.Text> */}

        </div>
       <span> #{selectedCard?.qaId}</span>
      </div>
      );
    };
    
    return (
    <Layout>
        <Card>
      <div style={{ padding: '10px 20px',height: '3%', display: 'flex', justifyContent: 'space-between', background: '#fff'}}>
        <div>
        <Title heading={5}>问答列表</Title>
        <span>{record.name}</span>
        </div>
        <Button type="secondary"  icon={<IconFile/>} onClick={handleAddCard}>添加分段</Button>
      </div>
      <Divider/>
      <Content style={{ padding: '20px' }}>
      <Card>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px',gap: '20px' }}>
          <Search allowClear  onSearch={(value) => setSearchParams(value)} placeholder="请输入问题后回车" style={{ width: 250 }}/>
          <Button icon={<IconRefresh/>} onClick={() =>{ setSearchParams(null) ; getQaDataList(currentPage, pageSize)}}></Button>
        </div>
        <Row gutter={[24, 24]}>
          { qaDataList && qaDataList?.records.map(item => (
            <Col xs={24} sm={12} md={6} key={item.qaId}>
              <Card 
                title={'#' + `${item.qaId}` }
                extra={<Popconfirm
                  focusLock
                  title="确认删除吗？"
                  okText="删除"
                  cancelText="取消"
                  onOk={(e) =>{ e.stopPropagation(); handleQaDelete(item.qaId)}}
                  onCancel={(e) => {
                    e.stopPropagation();
                    Message.error({
                      content: '取消删除',
                      duration: 1500,
                    });
                  }}
                >
                  <Button type="secondary" onClick={(e) => e.stopPropagation()} icon={<IconDelete />} size="small" style={{marginLeft: 10}} />
                </Popconfirm> 
                }
                hoverable={true}
                bordered={true}
                style={{  marginBottom: '24px' ,backgroundColor: hoveredCard === item.qaId ?'rgba(242,243,245, 1)':'#ffff' }}
                onMouseEnter={() => setHoveredCard(item.qaId)} // 设置当前悬浮的卡片 ID
                onMouseLeave={() => setHoveredCard(null)} // 取消悬浮时清空
                onClick={() => handleCardClick(item)} // 点击卡片时弹出模态框
              >
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <div>
                    {item.question}
                  </div>
                  {item.answer}
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        <Footer style={{marginTop:'30px', textAlign: 'center' }}>
          <Pagination
            showTotal
            current={currentPage}
            pageSize={pageSize}
            total={qaDataList?.total ? qaDataList.total : 0}
            onChange={(page, pageSize)=>getQaDataList(page, pageSize)}
            sizeCanChange={true}
          />
        </Footer>
        </Card>
      </Content>
      </Card>
      <Modal
        title={getTitle()} 
        visible={visible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        autoFocus={false}
        focusLock={true}
        maskClosable={false}
      >
        <Form layout="vertical">
          <Form.Item required label="问题">
            <ArcoInput.TextArea
            autoSize={{ minRows: 3,maxRows: 5 }}
              value={selectedCard?.question}
              onChange={(value) => setSelectedCard({ ...selectedCard, question: value })}
            />
          </Form.Item>
          <Form.Item required label="答案">
            <ArcoInput.TextArea
              value={selectedCard?.answer}
              style={{height: '100%', width: '100%' }}
              onChange={(value) => setSelectedCard({ ...selectedCard, answer: value })}
              autoSize={{ minRows: 3,maxRows: 5}}

            />
          </Form.Item>
        </Form>
      </Modal>
      
      <Modal
        title={`添加分段`}
        visible={addVisible}
        onOk={handleAddModalOk}
        onCancel={handleAddModalCancel}
        autoFocus={false}
        focusLock={true}
        maskClosable={false}
        
      >
        <Form layout="vertical">
          <Form.Item required label="问题">
            <ArcoInput.TextArea
              value={addCardText?.question}
              required
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={(value) => setAddCardText({ ...addCardText, question: value })}
            />
          </Form.Item>
          <Form.Item required label="答案">
            <ArcoInput.TextArea
              value={addCardText?.answer}
              required
              style={{height: '100%', width: '100%' }}
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={(value) => setAddCardText({ ...addCardText, answer: value })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
}

export default NotesPage;
