import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Select, Button,Typography, Card, Avatar, Upload, Space, Message } from '@arco-design/web-react';
import instance from '@/api/instance';
import { FormInstance } from '@arco-design/web-react/es/Form';
import {IKBDetail,DocumentInterface }from './interface';

const { Item: FormItem } = Form;

const ConfigPage = ({id,editData}) => {
    // TODO: 实现知识库配置页面

    const [loading, setLoading] = useState(true);
    const [data,setData] = useState([] as IKBDetail);
    const [chatList, setchatList] = useState([]);
    const [embedList, setembedtList] = useState([]);
    const [appList, setappList] = useState([]);
    const formRef = useRef<FormInstance>();
    const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    fetchData(id);
    getmodelData();
    getAppData();
    setLoading(false);
  }, [id]);

  useEffect(() => {
    // if (!loading) { 
    //   form.setFieldsValue(data); 
    // }
    form.setFieldsValue(data); 
  }, [data,id]);

  function fetchData(id) {
    //setLoading(true);
    instance
      .get(`/api/knowledge/${id}`)
      .then((res) => {
        if(res &&res.data && res.data.code==='0000'){
          setData(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('获取数据失败', error);
        //setLoading(false);
      });
  }
  const getmodelData = () => {
    instance
      .post('/api/model/list',
        {
          "apiProtocol": "OpenAI",
        },{
          headers: {
            'Content-Type': 'application/json',
        }})
      .then((res) => {
        if (res && res.data && res.data.code === '0000') {
        const chatList = [];
        const embedList = [];
        res.data.data.forEach(item => {
          if (item.type === 'Chat') {
            chatList.push({
              label: item.remark,
              value: item.modelId
            });
          } else if (item.type === 'Embeddings') {
            embedList.push({
              label: item.remark,
              value: item.modelId
            });
          }
        });
        setchatList(chatList);
        setembedtList(embedList);
        //console.log('chatList', chatList);
        //console.log('embedList', embedList);
        }
      })
      .catch((error) => {
        //Message.error('获取数据失败，请重试！');
        console.log(error);
      })
  }
  const getAppData = () => {
    instance
      .get('/api/app/list', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        //console.log('res', res.data.data);
        //console.log('res', res.data.data);
        if(res && res.data && res.data.code==='0000'){
          const appList = res.data.data.map(item => ({
            label: item.name,
            value: item.appId
          }));
          setappList(appList);
          //console.log('appList',appList);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function handleSubmit() {
    try{
      formRef.current.validate().then((data) => {
        console.log('知识库配置',data);
        setLoading(true);
      instance
        .post('/api/knowledge/update',
          data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          if(res && res.data && res.data.code==='0000'){
            Message.success('保存成功');
            fetchData(id);
            editData(data.name)
          }
        }).catch((error) => {
          Message.error('保存失败，请重试！');
        })
        .finally(() => {
          setLoading(false);
        });
      });
    }catch(error){
       Message.error('请检查输入！');
    }
  }

  // const handleDelete = (id) => {
  //   instance
  //    .delete('/api/delete', {
  //       params: {
  //         id,
  //       },
  //     })
  //    .then(() => {
  //       fetchData(id);
  //     });
  // };

  return (
    <Card>
          <Typography.Title heading={5} style={{ }}>知识库设置</Typography.Title>
      <span> 在这里您可以修改知识库的模型配置以及其它设置。</span>
      <Form form={form} ref={formRef} initialValues={data} layout="horizontal" style={{ maxWidth: 800, margin: '30 auto',marginTop:70,marginRight:50 }}>
        <FormItem label="知识库 ID" field='kbId'>
          <Input disabled />
        </FormItem>
        <FormItem label="知识库名称" field="name">
          <Input />
        </FormItem>
        <FormItem label="知识库描述" field="description">
          <Input.TextArea defaultValue="介绍" rows={3} />
        </FormItem>
        <FormItem label="聊天模型" field='chatModel'>
          <Select
            placeholder='请选择聊天模型'
            options={chatList}
            allowClear
          />
        </FormItem>
        <FormItem label="嵌入模型" field='embeddingModel'>
          <Select
            placeholder='请选择嵌入模型'
            options={embedList}
            allowClear
            disabled      
          />
        </FormItem>
        <FormItem label="知识库所属应用" field='appId'>
          <Select
            placeholder='请选择应用'
            options={appList}
            allowClear
          />
        </FormItem>
        <FormItem>
          <Space style={{display: 'flex', marginLeft: '51%', transform: 'translateX(-50%)'}}>
            <Button type="primary" onClick={handleSubmit}>保存</Button>
            {/* <Button type="default" onClick={handleDelete}>删除</Button> */}
          </Space>
        </FormItem>
      </Form>
    </Card>
  );
};

export default ConfigPage;

// import React, { useState, useEffect } from 'react';

// function MyForm() {
//   // 定义表单的初始状态
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     age: '',
//     // 更多字段...
//   });

//   // 模拟从服务器获取数据的异步函数
//   const fetchData = async () => {
//     try {
//       const response = await fetch('https://api.example.com/data');
//       const data = await response.json();
//       // 假设响应的数据结构是 { user: { name: 'John', email: 'john@example.com' }, profile: { age: 30 } }
//       // 转换数据以匹配 formData 结构
//       const transformedData = {
//         name: data.user.name,
//         email: data.user.email,
//         age: data.profile.age,
//         // 其他需要的字段...
//       };
//       return transformedData;
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       throw error; // 抛出错误以防止更新状态
//     }
//   };

//   // 在组件加载时调用 fetchData
//   useEffect(() => {
//     fetchData().then(transformedData => {
//       setFormData(transformedData);
//     }).catch(error => {
//       console.error('Failed to fetch and set initial data:', error);
//     });
//   }, []); // 空依赖数组表示这个 effect 只在组件加载时运行一次

//   // 处理表单字段变化的函数
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   // 处理表单提交的函数
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // 处理表单提交逻辑
//     console.log(formData);
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <input
//         type="text"
//         name="name"
//         value={formData.name}
//         onChange={handleChange}
//       />
//       <input
//         type="email"
//         name="email"
//         value={formData.email}
//         onChange={handleChange}
//       />
//       <input
//         type="number"
//         name="age"
//         value={formData.age}
//         onChange={handleChange}
//       />
//       {/* 更多字段 */}
//       <button type="submit">Submit</button>
//     </form>
//   );
// }

// export default MyForm;
