import React, { useReducer,useEffect, useState } from 'react';
import { Button, Collapse,Card, Typography,Space, Table } from '@arco-design/web-react';
import { useParams,useLocation } from 'react-router-dom';
import instance from '@/api/instance';
import styles from './style/index.module.less';

interface apiProps {
  id: string;
}


const CollapseItem = Collapse.Item;

const ApiDocumentation : React.FC<apiProps> = () =>{

//均无用
     const location = useLocation();
     const searchParams = new URLSearchParams(location.search);
     const appId = searchParams.get('appId');
    // // 使用 myParam 参数
    // console.log('myParam',myParam);
   // const {id}  = useParams();  
   // console.log('id',id);
   // console.log('key',key);
    //获取url中的参数
    
  const [response, setResponse] = useState<any>();

  //{response_mode: 'blocking',
  //  message_id: id,
  //  conversation_id: 'abcdef',
  //  mode: 'chat',
  //  answer: '这是示例的回答内容。',
  //  metadata:{},
  //  usage: {
  //    prompt_tokens: 10,
  //    completion_tokens: 50,
  //    total_tokens: 60,
  //  },
  //  retriever_resources: [],}

useEffect(() =>{
   fetchData();
  },[appId]);

  const fetchData = async () => {
    try {
      //const res = await fetch(`/api/agent/list${appId}`, {
      //  method: 'POST',
      //  headers: {
      //    'Content-Type': 'application/json',
      //  },
      // // body: JSON.stringify({ yourParameter: 'value' }),
      //});
      //const data = await res.json();
      //console.log('data',data);
      instance.post(`/api/agent/list`,{
          "appId": appId,
      }).then(res => {
      console.log('res',res);
      setResponse(res.data.data);
      }) 
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const argTypeDescriptions = {
    'Input': '单行文本框',
    'Textarea': '多行文本框',
    'Number': '数字输入',
    'Date': '日期时间',
    'Email': '邮箱',
    'Phone': '电话',
    'District': '地区',
    'Radio': '展开单选',
    'Select': '下拉单选',
    'MultiSelect': '多选',
    'Attachment': '附件',
    'MultiAttachment': '多附件上传',
};


  const inputColumns = [
    
    {
      title: '参数名称',
      dataIndex: 'name',
      render: (text) => text || '无',
  },
    {
        title: '参数字段key',
        dataIndex: 'key',
        render: (text) => text || '无',
    },
    {
      title: '参数类型',
      dataIndex: 'argType',
      render: (text) => argTypeDescriptions[text] || '无', 
  },
  {
      title: '数据类型',
      dataIndex: 'dataType',
      render: (text) => text || '无',
  },
 
    {
        title: '占位符',
        dataIndex: 'placeHolder',
        render: (text) => text || '无',
    },
    {
        title: '是否必填',
        dataIndex: 'require',
        render: (text) => (text),
    },
    {
        title: '其他校验规则',
        dataIndex: 'rule',
        render: (rule) => {
            return   rule  ?   `最大长度: ${rule?.maxLength || '无'}, 最大值: ${rule?.maxValue || '无'}, 最小值: ${rule?.minValue || '无'}` :'无' ;
        },
    },
    {
        title: '选项列表',
        dataIndex: 'selectList',
        render: (selectList) => {
            const defaultSelect = selectList && selectList.find(item => item.isDefault);
            return defaultSelect ? defaultSelect.name || '无' : '无';
        },
    },
];

const outputColumns = [
  {
      title: '参数名称',
      dataIndex: 'name',
      render: (text) => text || '无',
    },
    {
        title: '参数字段key',
        dataIndex: 'key',
        render: (text) => text || '无',
      },
      {
          title: '描述',
          dataIndex: 'description',
          render: (text) => text || '无',
        },
        {
            title: '数据类型',
            dataIndex: 'dataType',
            render: (text) => text || '无',
        },
        {
            title: '绑定参数配置',
            dataIndex: 'bindKey',
            render: (text) => text || '无',
        },
];


  return (
    <div>
        <Card>
        <Typography.Title heading={6} style={{marginBottom:'10px'}} >{'API文档'}</Typography.Title>
        <div>
      
        {response && response.map((item,index)=> (
       <Collapse key={index} bordered={false} accordion>
         <CollapseItem key= {index} style={{borderBottom:'0px solid #f0f0f0'}}  header={item.name} name={item.name}>
                      <Typography.Paragraph className={styles['paragraph-content']}><strong>模型名称</strong> <span> {item.pubName ? item.pubName : item.name }</span></Typography.Paragraph>
                      <Typography.Paragraph className={styles['paragraph-content']}><strong>类型</strong> <span> {item.agentType}</span></Typography.Paragraph>
                      <Typography.Paragraph className={styles['paragraph-content']}><strong>接口地址</strong> <span> {item.path}</span></Typography.Paragraph>
                      <Typography.Paragraph className={styles['paragraph-content']}><strong>描述</strong> <span> {item.description ? item.description : '无' }</span></Typography.Paragraph>
                      <Typography.Paragraph className={styles['paragraph-content']}>
                          <strong>消息内容是否必须</strong>
                          <span> {item.msgRequire==1 ? '是' : '否'}</span>
                      </Typography.Paragraph  >
                      {item.agentType && item.agentType === 'Agent' &&
                      <>
                      <Typography.Paragraph className={styles['paragraph-content']}>
                          <strong>自动回复消息内容</strong>
                          <span> {item.autoChatMsg ? item.autoChatMsg : '无'}</span>
                      </Typography.Paragraph>
                      {item.outputType && item.outputType !== 'Text' &&
                      <Typography.Paragraph className={styles['paragraph-content']}>
                          <strong>格式化输出提示词</strong>
                          <span> {item.formatPrompt ? item.formatPrompt : '无'}</span>
                      </Typography.Paragraph>
                      }
                      <Typography.Paragraph className={styles['paragraph-content']}>
                          <strong>是否允许附件</strong>
                          <span> {item.allowAttachment ? '是' : '否'}</span>
                      </Typography.Paragraph>
                      <Typography.Paragraph className={styles['paragraph-content']}>
                          <strong>是否开启语音</strong>
                          <span> {item.openVoice ? '是' : '否'}</span>
                      </Typography.Paragraph>
                      <Typography.Paragraph className={styles['paragraph-content']}>
                          <strong>是否流式输出</strong>
                          <span> {item.streamOut ? '是' : '否'}</span>
                      </Typography.Paragraph>
                      </>
                      }
                      <Typography.Paragraph className={styles['paragraph-content']}>
                          <strong>输入类型</strong>
                          <span> {item.inputType==='Text' ? '文本' :item.inputType==='Dict' ? '字典' :item.inputType==='Auto' ? '数组' :'无'  }</span>
                      </Typography.Paragraph>
                      <Typography.Paragraph style={{marginBottom:'10px',paddingBottom:'20px'}}>
                      { item.inputType && item.inputType !== 'Text' && 
                        <>
                          <strong>输入参数</strong>
                          <Table
                          style={{marginBottom:'15px'}}
                          data={ item.inputArgs &&item.inputArgs.map(item => ({
                            key: item.key, // 为每个行提供唯一的 key 属性,否则浏览器会报错warning
                            ...item,
                          }))}
                          columns={inputColumns}
                          />
                          </>
                          }
                      </Typography.Paragraph>
                      <Typography.Paragraph style={{marginBottom:'10px'}}>
                          <strong>输出类型</strong>
                          <span> {item.outputType==='Text' ? '文本' :item.outputType==='Dict' ? '字典' :item.outputType==='Auto' ? '数组' :'无'  }</span>
                      </Typography.Paragraph>
                    { item.outputType && item.outputType == 'Dict' && 
                      <Typography.Paragraph className={styles['paragraph-content']}>
                          <strong>输出参数</strong>
                          <Table
                          data={item.outputArgs&&item.outputArgs.map(item => ({
                            key: item.key, // 为每个行提供唯一的 key 属性,否则浏览器会报错warning
                            ...item,
                          }))}
                          columns={outputColumns}
                          />
                      </Typography.Paragraph>
                    }
                     
                      
                     

          </CollapseItem>
          {/*<CollapseItem key= {index} style={{borderBottom:'0px solid #f0f0f0'}} header="点击展开/收缩面板 1" name="2">
                      <Typography.Title heading={3}>Response</Typography.Title>
                      <Typography.Paragraph>
                          <strong>response_mode:</strong> <span> {response.response_mode}</span>
                      </Typography.Paragraph>

                      <Typography.Title heading={5}>ChatCompletionResponse</Typography.Title>
                      <Typography.Paragraph>
                         <Space>
                          <strong>message_id:</strong> {item.name}
                          </Space>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                          <strong>conversation_id:</strong> {item.name}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                          <strong>mode:</strong> {item.name}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                          <strong>answer:</strong> {response.answer}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                          <strong>metadata:</strong> {JSON.stringify(response.metadata, null, 2)}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                          <strong>usage:</strong> {JSON.stringify(response.usage, null, 2)}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                          <strong>retriever_resources:</strong> {JSON.stringify(response.retriever_resources, null, 2)}
                      </Typography.Paragraph>
                </CollapseItem>*/}
               
               
       
          </Collapse>
        ))
                }
        </div>
        </Card>
    </div>
  );
}

export default ApiDocumentation;